.logo-header{
    width: 200px;
}

.header-all-pages{
    background-color: #16385C;
    padding: 0px 15px 0px 15px;
}

#user-email{
    color: #FFFF;
}

.fa-info{
    color: #FFFF;
    padding-right: 10px;
    padding-left: 10px;
}