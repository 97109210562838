/* #create-new-link{
    background-color: #1C5FA6
} */
#create-new-campaign{
    background-color: #4195D1;
}

html{background-color: #EFF0F6}

.submit-button{
    background-color: #1C5FA6;
}