.login-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background-color: #002B4A;
}
.login-elements {
  /* display: flexbox;
  flex-direction: column; */
  min-width: 450px;
  margin: 0 auto;
  text-align: left;
  background-color: #EFF0F6;
  border-radius: 15px;
  padding: 40px 40px 0px 40px;
}

.login-elements input{
  background-color: #d6e2ec;
}
.login-logo{
  max-width:100%;
  width: 200px;
  height: auto;
  padding-left: 5px;
  padding-top: 15px;
  margin-bottom: 30px;
}
.login-form{
  text-align: left;
}

/* Shared */
.loginBtn {

  background-color: #1C5FA6;
  margin-bottom: 10px;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}


.login-title{
  color: #25282B;
}

#password,#email{
  background-color: #d6e2ec;
}

#reset-password{
  color: #FFFFFF;
  text-decoration: underline;
  font-size: 16px;
  margin-bottom: 10px;
}