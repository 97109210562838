.campaign-card{
    background-color: #FFFF;
    border-radius: 25px;
    margin-bottom: 15px;
}

.card-header{
    background-color: #16385C;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 25px 25px 0px 0px;
    margin-bottom: 0px;
}

.card-title{
    color: #ffff;
    font-size: 20px;
    font-weight: 700;
}

.campaign-info{
    font-size: 14px;
    color: #ffff;
}

p{
    padding: 0px;
    margin: 0px;
}

.tag kbd{
    margin-top: 12px;
    font-size: 14px;
    margin-left: 5px;
    padding: 2px 4px 2px 4px;
}

.segment{
    background-color: #4195D1;
}
.strategy{
    background-color:#F9B036;
}
.competencia{
    background-color: #83A9BC;
}


.sem-links{
    background-color: #f8d7da; 
    color: #721c24;
    padding: 8px; 
    border: 1px solid #f5c6cb; 
    border-radius: 4px; 
    font-weight: bold; 
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.actions button{
    padding: 0px;
    font-size: 15px;
    margin: 0px;
    border: none;
    background-color: #16385C;
    width: 25px;
}

.delete{
    background-color: #E74E15;
}

.copy{
    background-color: #A5C720;
}

.copy-naming{
    margin-left: 5px;
    padding: 3px;
    cursor: pointer;
}
.copy-naming:hover{
    margin-left: 5px;
    padding: 3px;
    cursor: copy;
}

thead tr th{
    font-weight: 600;
    font-size: 18px;
}

.tooltip-kbd{
    text-align: center;
    max-width: 18px;
}

.cria-link{
    background-color: none;
}